import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Yoast from 'components/globals/yoast';
import Link from 'components/link/link';
import Wistia from 'components/video/wistia-player';

import './styles/transform-your-workplace.scss';

const TransformYourWorkplace = () => {
  const data = useStaticQuery(graphql`
   {
     allWordpressPage(filter: { slug: { eq: "transform-your-workplace" } }) {
       edges {
         node {
          wordpress_id
          yoast_head_json {
            title
            description
            canonical
            og_title
            og_description
            og_image {
              url {
                source_url
              }
            }
            og_url
            og_type
            twitter_title
            twitter_description
            twitter_image {
              source_url
            }
          }
         }
       }
     }
   }
 `);

  const pageContext = data.allWordpressPage.edges[0].node;

  return (
    <Layout>    
      <Yoast { ...pageContext.yoast_head_json } />
      <section className="section transform-your-workplace-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container">
          <h1 className="subhead">Transform Your Workplace in 10 Minutes</h1>
          <h2 className="headline">Before you watch make sure you've:</h2>
          <ul>
            <li><Link to="/signup">Created your When I Work account.</Link></li>
          </ul>

          <h2 className="headline">In this video you will learn how to:</h2>
          <ul>
            <li>Quickly build the work schedule and instantly share it with your team</li>
            <li>Improve team communication with a private workplace messaging app that puts all team communication in one place</li>
            <li>Control labor costs by turning any device into a time clock that tracks attendance, enforces the work schedule, and integrates with your payroll provider.</li>
          </ul>
          <Wistia hashedId="dpiqwouzaj" className="wistia-video" />
        </div>
      </section>
    </Layout>

  );
};

export default TransformYourWorkplace;